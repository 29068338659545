//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit("getItem", val);
    }
  },
  props: {
    pageData: Array,
    loading: Boolean
  }
};
