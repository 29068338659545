// const __request = require(`./__request/__request_contentType_json`)
const __request = require('./requestExcel');

const request = (data) => {
    let urlString = '?'
    // Object.keys(data).map((key) => {
    //     data[key].constructor === Array ?
    //         urlString += `ids=${data[key].join(',')
    //         }&` : urlString += `${key}=${data[key]}&`
    // })
    let pParameter = {
        method: 'post',
        // urlSuffix: '/flight/order/report/export' + urlString,
        urlSuffix: '/flight/order/report/export',
        data: data
    }
    console.log(data)
    return __request(pParameter)
}
export default request