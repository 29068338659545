const axios = require(`axios`);
const Q = require('q');
const get_url_prefix = require(`yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix`);
const get_token = require(`yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_token`);

const urlPrefix = get_url_prefix({
    environment: global.appConfig.environment,
    appKey: 'flight',
});
let options = {
    method: 'POST',
    baseURL: urlPrefix,
    headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        token: get_token()
    },
    // transformRequest: [function (data) {
    //     return qs.stringify(data);
    // }],
    data: {},
    responseType: 'arraybuffer'
};


function requestExcel(val) {
    window.open(`http:${urlPrefix + val.urlSuffix}token=${get_token()}`)
}

function request(val) {
    var data = extend(options, val)
    return Q.when()
        .then(function () {
            return axios(data).then(res => {
                if (res.status == 200) {
                    return Q.resolve(res.data)
                } else {
                    return Q.reject(res)
                }
            })
        })
}

function extend(target, source) {
    for (var i in source) {
        target.url = source.urlSuffix
        target[i] = source[i];
    }
    return target
}

module.exports = request;
